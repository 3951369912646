var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","permanent":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('project-sync-tools',{attrs:{"project":_vm.currentProject}})]},proxy:true}])},[_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-list-item',_vm._g({},onMenu),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-list-item-title',_vm._g({},onTooltip),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder-multiple-outline")]),_vm._v(" "+_vm._s(_vm.currentProject ? _vm.currentProject.name : 'Select a Project')+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.currentProject ? _vm.currentProject.name : 'Select a Project')+" ")])]),(_vm.currentProject)?_c('v-list-item-action',[_c('files-add-folder',{attrs:{"project":_vm.currentProject},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-folder-plus-outline")])],1)]}}],null,true)})],1):_vm._e(),(_vm.currentProject)?_c('v-list-item-action',[_c('files-add-file',{attrs:{"project":_vm.currentProject},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-file-plus-outline")])],1)]}}],null,true)})],1):_vm._e()],1)]}}])},[_c('recent-list',{attrs:{"manage-label":"Project Manger...","manage-link":"/tools/projects","service":"projects"}})],1),_c('v-divider'),_c('files-tree')],1),_c('v-main',{staticStyle:{"padding-left":"256px"}},[(_vm.currentProject)?_c('files-editor'):_c('project-manager')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }