var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!!_vm.project.uuid),expression:"!!project.uuid"}],attrs:{"icon":"","loading":_vm.exportLoading},on:{"click":_vm.exportProject}},on),[_c('v-icon',[_vm._v("mdi mdi-folder-download-outline")])],1)]}}])},[_c('span',[_vm._v("Export Project (Save)")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('save-project',{attrs:{"import-project":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onSave = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onTooltip, onSave)),[_c('v-icon',[_vm._v("mdi mdi-folder-upload-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Import Project (Open)")])])]}}])})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"value":!!_vm.libs,"content":_vm.libs,"overlap":""}},[_c('v-btn',_vm._g({attrs:{"icon":"","to":"/tools/libraries"}},on),[_c('v-icon',[_vm._v("mdi mdi-book-open-variant")])],1)],1)]}}])},[(_vm.libs)?_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v("Libraries")]),_vm._l((_vm.project.libraries),function(lib,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(lib.name)+" - "+_vm._s(lib.version)+" ")])})],2):_c('strong',[_vm._v("Libraries")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }